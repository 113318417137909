import React from 'react'
import { graphql, PageProps } from 'gatsby'
import _get from 'lodash/get';

import withLocalization from 'decorators/withLocalization';
import ContentBox from 'widgets/ContentBox';
import PageLayout from 'components/Layout/PageLayout';
import { PageQuery } from '../../generated/graphql-operations'

const Page: React.FC<PageProps<PageQuery>> = ({
  data: rawData,
}) => {
  const data = rawData.sanityPage
  const heroProps = {
    type: 'polygon',
    heading: null,
  }

  if (!data) {
    return null
  }

  return (
    <PageLayout data={data} heroProps={heroProps}>
      <ContentBox blocks={_get(data, '_rawContent')} />
    </PageLayout>
  )
}

export const query = graphql`
  query Page($id: String, $language: String!, $contentDepth: Int!) {
    sanityPage(id: { eq: $id }) {
      hero {
        ...HeroFragment
      }
      title {
        localized
      }
      meta {
        ...MetaFragment
      }
      contact {
        ...ContactFragment
      }
      _rawContent(resolveReferences: { maxDepth: $contentDepth })
      palette
    }
    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withLocalization(Page);
